<template>
    <b-container class="c-pages">
        <div class="c-page__title" >
            <div>
                <h1>Stránky</h1>
            </div>
            <div class="c-page__controls">
                <b-icon class="clickable" icon="plus-square-fill" variant="info"
                        @click="addNewItem()" title="Přidat stránku"></b-icon>
            </div>
        </div>
        <ul class="c-main-list">
            <template v-for="item in posts.records" >
                <li :key="'item_'+item.id" >
                    <a v-if="item.type === 'single'" class="c-main-list__name clickable"  >
                        <router-link :to="{name: 'pagesPartsEdit', params: {id: item.pages_parts[0].id}}" title="Editace obsahu">
                            {{item.name}}
                        </router-link>
                    </a>
                    <a v-else-if="item.type === 'multiple'" class="c-main-list__name clickable" v-b-toggle="'collapse'+item.id" >
                        {{item.name}}
                    </a>
                    <span v-else class="c-main-list__name" >
                        {{item.name}}
                    </span>
                    <div class="c-pages__url">
                        <a :href="pageUrl(item.editable, item.url)" target="_blank" title="Náhled stránky">
                            {{ pageUrl(item.editable, item.url) }}
                        </a>
                    </div>
                    <div class="text-right c-page__table-edit">
                        <router-link :to="{name: 'pagesEdit', params: {id: item.id}}" title="Nastavení stránky">
                            <b-icon class="clickable" icon="gear" variant="info" ></b-icon>
                        </router-link>

                    </div>
                    <div class="w-100">
                        <b-collapse :id="'collapse'+item.id">
                            <ul class="c-page__list mt-2" >
                                <li v-for="page in item.pages_parts" :key="page.id">
                                    <router-link :to="{name: 'pagesPartsEdit', params: {id: page.id}}" title="Editace obsahu">
                                        {{page.name}}
                                    </router-link>
                                </li>
                            </ul>
                        </b-collapse>
                    </div>
                </li>
            </template>
        </ul>
        <router-view @loadData="loadData()"></router-view>
    </b-container>

</template>

<script>

    export default {
        name: 'pages',

        data() {
            return {
                posts: [],
                path: process.env.VUE_APP_SERVER
            }
        },
        methods: {
            async loadData() {
                try {
                    const response = await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/pages/pages.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        method: 'GET'
                    });

                    this.posts = response.data;

                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', 'Žádná data');
                }

            },
            async addNewItem() {

                try {
                    const response = await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/pages/add-new-page.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        method: 'POST'
                    });

                    this.$router.push( {name: 'pagesEdit', params: {id: response.data.id}} );



                } catch (error) {
                    this.loadData();
                    console.log("error", error);
                }

            },
            pageUrl(editable, url) {
                let lang = this.$i18n.locale;
                return this.path + lang + '/page/' + url;
            }
        },
        computed: {
            languages() {
                return this.$store.getters.langs
            },
        },
        mounted () {
            this.loadData();

        }
    }
</script>

<style scoped lang="scss">
    .c-pages {
        &__list {
            li {
                width: 100%;
            }
        }
        &__url {
            flex: 1;
        }
    }
</style>
